<template>
    <div class="sph">
        <div class="spbq-box" v-for="(item,index) in modelList" :key="item.ID+'i'+index">
          <div class="spbq" @mouseenter="mouseover($event,item.id)" @mouseleave="mouseleave($event,item.id)">
            <div class="biankuang biankuang_1 hidAnimate animate__animated animate__slideInDown" :class="'animate_'+item.id"></div>
            <div class="biankuang biankuang_2 hidAnimate animate__animated animate__lightSpeedInLeft" :class="'animate_'+item.id"></div>
            <div class="biankuang biankuang_3 hidAnimate animate__animated animate__slideInUp" :class="'animate_'+item.id"></div>
            <div class="biankuang biankuang_4 hidAnimate animate__animated animate__lightSpeedInRight" :class="'animate_'+item.id"></div>
            <a>
              <div class="zst">
                <img :src="item.modelImgUrlStr || initImageUrl" :onerror="errorImage" alt="" class="img"/>
              </div>
              <div>
                <el-row v-show="!item.isChange">
                  <el-col :span="20">
                    <div class="modelName">
                      {{ item.modelName | filterModelName }}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <span title="删除项目" @click="deleteProject(index, item.id)" class="deleteProject">
                      <i class="el-icon-delete"></i></span>
                  </el-col>
                  <el-col :span="2">
                    <span title="编辑项目" @click.stop="editProject(index)" class="editProject">
                      <i class="el-icon-edit-outline"></i>
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-input v-focus="focusState" placeholder="输入模型名称"  v-model="modelName" v-show="item.isChange" @blur="sureChange(item,index)"></el-input>
                </el-row>
              </div>
            </a>
            <div class="text_gobuy" :id="item.id" @click="enterModel(item)">
              <br />
              <p class="enterModel">
                进入模型
              </p>
              <br />
            </div>
          </div>
        </div>
        <div class="spbq-box" v-for="item in addSpbqBoxLength" :key="item"></div>
      </div>
</template>

<script>
import '.././../assets/css/modelListCSS.css'
export default {
  data () {
    return {
      show: false,
      modelList: [], // 模型列表
      initImageUrl: '../assets/morenModel.png',
      errorImage: 'this.src="' + require('../../assets/morenModel.png') + '"',
      addSpbqBoxLength: 0,
      modelName: '',
      focusState: false
    }
  },
  filters: {
    filterModelName (item) {
      if (item.length > 9) {
        return item.slice(0, 9) + '...'
      } else {
        return item
      }
    }
  },
  directives: {
    focus: {
      update: function (el, {
        value
      }) {
        if (value) {
          el.children[0].focus()
        }
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$axios.get('/api/model/GetModelList').then(res => {
        res.forEach(item => {
          item.isChange = false
        })
        this.modelList = res
        this.addSpbqBoxLength = 6 - this.modelList.length % 6
        this.addSpbqBoxLength === 6 && (this.addSpbqBoxLength = 1)
      })
      // 新增得spbq-box数目 占位
    },
    // 进入模型
    enterModel (item) {
      console.log('进入模型')
      localStorage.setItem('modelInfo', JSON.stringify(item))
      this.$router.push('/home/model')
    },
    deleteProject (index, id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/model/DeleteModel?modelId=' + id).then(res => {
          this.modelList.splice(index, 1)
          this.addSpbqBoxLength = 6 - this.modelList.length % 6
          this.addSpbqBoxLength === 6 && (this.addSpbqBoxLength = 1)
          this.$message({
            type: 'success',
            message: '删除成功'
          })
        })
      }).catch(() => {
      })
    },
    editProject (index) {
      let item = this.modelList[index]
      item.isChange = !item.isChange
      this.modelName = item.modelName
      this.$set(this.modelList, index, item)
      setTimeout(() => {
        this.focusState = true
      }, 1)
    },
    sureChange (item, index) {
      if (this.modelName === '') {
        this.$message({
          type: 'warning',
          message: '模型名称不能未空'
        })
        let item1 = this.modelList[index]
        item1.isChange = !item1.isChange
        this.$set(this.modelList, index, item1)
        this.focusState = false
        return
      }
      if (item.modelName === this.modelName) {
        this.$message({
          type: 'warning',
          message: '模型名称未更改'
        })
        let item1 = this.modelList[index]
        item1.isChange = !item1.isChange
        this.$set(this.modelList, index, item1)
        this.focusState = false
        return
      }
      var form = {}
      form.ModelName = this.modelName
      form.Id = item.id
      this.$axios.post('/api/model/UpdateModel', form).then(res => {
        item.modelName = this.modelName
        this.$message({
          type: 'success',
          message: res
        })
        let item1 = this.modelList[index]
        this.$set(this.modelList, index, item1)
        item1.isChange = !item1.isChange
        this.modelName = ''
        this.focusState = false
      }, error => {
        let item1 = this.modelList[index]
        item1.isChange = !item1.isChange
        this.modelName = ''
        this.focusState = false
        return Promise.reject(error)
      })
    },
    // 边框效果--移入
    mouseover (obj, id) {
      var el = document.getElementById(id)
      el.classList.add('text_gobuy_show')
      var animates = document.getElementsByClassName('animate_' + id)
      for (var i = 0; i < animates.length; i++) {
        animates[i].classList.remove('hidAnimate')
      }
    },
    // 边框效果--移出
    mouseleave (obj, id) {
      var el = document.getElementById(id)
      el.classList.remove('text_gobuy_show')
      var animates = document.getElementsByClassName('animate_' + id)
      for (var i = 0; i < animates.length; i++) {
        animates[i].classList.add('hidAnimate')
      }
    }
  }
}
</script>

<style>
</style>
